.categoryName {
    margin-bottom: 0px;
    font-weight: 700;
}

.rate,
.priceDate {
    font-size: 14px;
    margin-bottom: 0px;
}

.openCloseStatus {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    margin-right: 3px !important;
}

.booked {
    background-color: #e2707069;
    padding: 5px 0px;
    padding-left: 5px;
    border-radius: 6px;
    color: #c95050;
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 100%;
    width: 100%;
}

.available {
    background-color: #52bd5275;
    padding: 5px 0px;
    padding-left: 5px;
    border-radius: 6px;
    color: #24a424;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.loadingCalendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 20vh);
}

.loadingCalendar p {
    font-size: 25px;
    color: #e89532;
    font-weight: 600;
}

.loadingCalendar img {
    width: 380px;
    height: 300px;
}

.selectedBox {
    border: 1px solid black;
}

.ficed-top-nbar {
    display: flex;
    justify-content: space-between;
    top: 0;
    padding: 10px 0;
    z-index: 1;
    margin-bottom: 10px;
}

.ficed-top-nbar input {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 100px;
    padding: 7px 10px 7px 16px;
    position: relative;
}

.list-pro {
    min-width: 330px;
    z-index: 99;
    background: #fff;
    border-radius: 10px;
    padding: 20px 15px;
    position: absolute;
    top: 59px;
    right: 0;
    border: 1px solid #ddd;
    max-height: 400px;
    overflow-y: auto;
}

.list-pro ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.list-pro ul li div {
    display: flex;
    align-items: center;
}

.list-pro ul li div img {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.list-pro ul li:last-child {
    margin: 0;
}

.list-pro ul li .text-prop {
    flex-direction: column;
    align-items: flex-start;
}

.list-pro ul {
    padding: 0;
    margin: 0;
}

.cat-prop button {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 100px;
    padding: 7px 40px 7px 7px;
    position: relative;
}

.list-pro ul li .text-prop h6 {
    margin: 0;
}

.list-pro ul li .text-prop p {
    margin: 0;
    font-size: 11px;
    text-align: left;
}


.cat-prop button img {
    border-radius: 100px;
    max-height: 35px;
    max-width: 35px;
    object-fit: cover;
    margin-right: 10px;
}

.cat-prop button::before {
    content: "";
    position: absolute;
    right: 14px;
    top: 18px;
    width: 8px;
    height: 8px;
    border: 2px solid #c0c0c0;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
}

.cat-prop {
    position: relative;
}

.outer-list-pro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.av-cal-list-grid__cell {
    border: 1px solid #e0e0e0;
    border-bottom-width: 0;
    border-left-width: 0;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    line-height: 1;
}

.av-cal-list-grid__cell--separator {
    border-right-width: 2px;
    border-right-color: gray;
}

.av-cal-list-cell {
    position: relative;
    background: #fff;
    text-align: right;
    padding: 14px 5px;
    min-width: 60px;
    line-height: 17px;
    border-top: 0;
}

.border-right-0 {
    border: 0;
}

.outre-calnder-grid-outer {
    display: flex;
    position: relative;
    padding-bottom: 8px;
    overflow-x: auto;
}

.rooms-outer {
    overflow: hidden;
}

.outre-calnder-grid-outer::-webkit-scrollbar,
.rooms-outer::-webkit-scrollbar {
    width: 5px;
}

.outre-calnder-grid-outer::-webkit-scrollbar-track,
.rooms-outer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.outre-calnder-grid-outer::-webkit-scrollbar-thumb,
.rooms-outer::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
}

.outre-calnder-grid-outer::-webkit-scrollbar-thumb:hover,
.rooms-outer::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.outre-calnder-grid {
    display: flex;
    flex-direction: column;
}

.day-cal span {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    width: 100%;
    display: table;
}

.empty-day-cal {
    margin-top: 8px;
}

.dates-inner {
    display: flex;
    height: 100%;
}

.av-cal-list-date {
    color: #6b6b6b;
}

.av-cal-list-date--weekend {
    font-weight: 700;
    color: #333;
}

.av-cal-list-date__day-number {
    display: block;
}

.lower-calander-data {
    margin-top: 30px;
}

.top-link-calander {
    display: flex;
    justify-content: space-between;
}

.top-link-calander h4 {
    font-size: 20px;
}

.top-link-calander h4 span {
    color: #8d8d8d;
    font-size: 16px;
}


.room-sell label {
    border: 1px solid #e0e0e0;
    padding: 9px;
    min-width: 180px;
    font-size: 12px;
    min-height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.romstarus {
    display: flex;
}

.mg-n {
    margin-top: 22px;
    min-width: 180px;
}

.romstarus span {
    background: #c6f1c9;
    border-radius: 100px;
    padding: 5px 20px;
    width: 100%;
    margin-left: 10px;
}

.room-sell {
    display: flex;
    align-items: center;
}

.room-sell label span {
    display: block;
    font-size: 14px;
    color: var(--bs-link-color);
}

.room-label {
    width: 180px;
    font-size: 12px;
}

.available-bookings {
    width: 0px !important;
    height: 0px !important;
    visibility: hidden;
}

.room-rount-header {
    font-size: 13px;
    text-align: right;
    min-width: 60px;
    min-height: 62px;
}

.room-rount-header span {
    display: block;
    font-size: 13px;
    color: #707070;
}

.room-rount {
    border: 1px solid #e0e0e0;
    padding: 9px 5px;
    font-size: 13px;
    min-width: 60px;
    min-height: 62px;
    height: 62px;
    text-align: right;
}

.room-rount span {
    display: block;
    font-size: 11px;
    color: #707070;
}

.rooms-count {
    display: flex;
}

.calander-outer {
    background: #f9f9f9;
    padding: 26px;
    border-radius: 10px;
}

.sech-btn {
    background: #e89532;
    padding: 7px 16px;
    border-radius: 5px;
    color: #fff;
}

.sech-btn:hover {
    background: #000;
}
::-moz-selection { /* Code for Firefox */
    color: #000;
    background: transparent;
  }
  
  /* ::selection {
    color: #000;
    background: transparent;
  } */
.selected-left-box {
    /* border-left: 2px solid black !important;
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; */
    background: #e895323d;
}

.selected-box {
    /* border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-radius: 0px !important; */
    background: #e895323d;
}

.selected-right-box {
    /* border-right: 2px solid black !important;
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; */
    background: #e895323d;
}


@media (max-width:767px) {
    .ficed-top-nbar {
        flex-wrap: wrap;
    }
    .calander-outer {
        padding: 3px;
    }
    .cat-prop button {
        margin: 10px 0 0;
    }
    .heading {
        font-size: 15px;
    }
    .ficed-top-nbar .d-inline-flex.align-items-center {
        flex-wrap: wrap;
        width: 100%;
    }

    .ficed-top-nbar .d-inline-flex.align-items-center div {
        width: 100%;
        margin: 1px 0 !important;
    }

    .cat-prop,
    .cat-prop button,
    .ficed-top-nbar input {
        width: 100%;
    }
    
    .calander-outer h5 {
        font-size: 17px;
    }

}