.main-outer-chat {
    margin-top: 10px;
}

.left-chat-hd {
    display: flex;
    align-items: center;
}

.left-chat a {
    text-decoration: none;
}

.message-box {
    position: relative !important;
    height: calc(100vh - 320px) !important;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.message-box div {
    width: 100%;
}

.left-chat li {
    list-style: none;
}

.left-chat {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
}

.left-chat li {
    list-style: none;
}

.left-chat .fw-bold {
    font-weight: 600 !important;
    color: #595959;
}

.left-chat a {
    text-decoration: none;
}

.left-chat-hd {
    display: flex;
    align-items: center;
}

.caht-head span {
    font-size: 13px;
    color: #767676;
}

.caht-head ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.caht-head i {
    font-size: 21px;
    color: #707070;
}

.caht-head {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0px 24px 16px;
    justify-content: space-between;
    border-radius: 0;
    border-bottom: 1px solid #e89532;
}

HEAD .message-box {
    position: relative !important;
    height: calc(100vh - 320px) !important;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.chat-otr {
    background: #fff;
    border: 1px solid #ddd;
    padding: 17px 0;
}

.detils h4 {
    margin: 0;
    font-size: 17px;
}

.chat-otr .text-muted {
    padding: 0 20px;
}

.senderImage {
    width: 45px;
    height: 40px;
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 8px;
}

#chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
}

#chat3 .form-control {
    border-color: transparent;
    height: 46px;
    border: 1px solid #ddd;
    width: 100%;
    padding: 18px;
}

.chat-otr a.text-muted {
    padding: 0;
}
.chat-icon {
    width: 160px;
    height: 160px;
}
.message {
    font-size: 16px;
    background: #fff;
    outline: none;
}

.rounded {
    position: relative;
}

.badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -0.75rem;
}

p.small.text-muted {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px;
    height: 1.4em;
    white-space: nowrap;
}

.details h4 {
    margin: 0;
    font-size: 17px;
}

.left-chat li .d-flex.flex-row .pt-1 p.small.text-muted {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px;
    height: 1.2em;
    white-space: nowrap;
}

.rese-deails ul {
    padding: 0;
}

.rese-deails ul li label {
    font-style: italic;
    font-weight: 600;
    min-width: 121px;
}

.rese-deails ul li {
    list-style: none;
    margin-top: 9px;
    font-size: 17px;
}

@media (max-width:767px) {
    .left-chat .fw-bold {
        font-size: 13px;
    }

    .left-chat li p.small.text-muted {
        font-size: 11px;
    }
    .left-chat div {
        height: auto !important;
    }
}