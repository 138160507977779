.bathroom-select h5 {
  width: auto;
  float: left;
  padding: 0 20px 0 0;
  margin-bottom: 0px !important;
}

.bathroom-select hr {
  margin-bottom: 21px;
}

.special-ammenity {
  background-color: #e89532 !important;
}

.aminityi {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-wrap: wrap;
  padding: 9px;
  min-height: 52px;
}

.aminityi div {
  margin-right: 4px;
  background-color: #dcd9d9;
  font-size: 15px;
  margin-bottom: 3px;
  align-self: center;
  padding: 3px 8px;
  border-radius: 4px;
}

.bathrom-options h6 {
  font-size: 20px;
  margin: 6px 0 18px;
}

.bathrom-options {
  background: #f3f3f3;
  padding: 14px;
  border-radius: 12px;
  margin: 9px 0 10px;
}

.add-onother-nbed {
  color: #e89532;
  cursor: pointer;
}

.add-onother-nbed i {
  margin-right: 5px;
}

/* .inner-option .form-group input {
    width: auto;
    height: auto;
} */

.aminity-box {
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 5px;
  padding: 17px;
  display: flex;
  flex-wrap: wrap;
}

.checkBoxContainer {
  display: flex;
  width: 33.33%;
  text-align: left;
  margin-bottom: 13px;
}

.aminity-box input {
  appearance: none;
  width: 20px !important;
  height: 20px;
  margin-right: 8px;
  padding: 0;
}

.aminity-box input:checked {
  background-color: #353131;
}
.airbnForm label {
  width: 100%;
  margin-bottom: 3px;
}
.airbnForm input {
  width: 100%;
}
.airbnForm .fromGroup {
  margin-bottom: 14px;
}
.airbnForm input[type="submit"] {
  background: #e89532;
  color: #fff;
  border: 0;
}
.waitPop {
  position: absolute;
  z-index: 1;
  background: #fff;
  padding: 20px 30px 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px #ddd;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: table;
  top: 50%;
  transform: translateY(-50%);
}
.bathrom-options input {
  width: auto;
  height: auto;
}
.bathrom-options span {
  padding-right: 20px;
}
.waitPop img {
  max-width: 100px;
  margin: 0 auto;
  display: table;
}
.waitPop h5 {
  margin: 22px 0 0;
  color: #e89532;
}
.waiOuter {
  background: #00000029;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  bottom: 0;
}
.aminity-box input {
  min-width: 20px;
}

.nextBtn {
  margin-left: "auto";
  float: "right";
  margin-top: "10px";
}
@media (max-width: 767px) {
  .checkBoxContainer {
    width: 100%;
  }
  .checkBoxContainer p {
    font-size: 15px;
  }
  .aminity-box input {
    min-width: 20px;
  }
  .togglr-btn {
    display: none;
  }
}
