.button {
    background: #e89532;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    padding: 4px 20px;
    border-radius: 5px;
    border-color: transparent;
}
.button:hover
{
    background: #000;
}