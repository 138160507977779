.listing-table table {
    width: 100%;
}

.listing-table table tr th {
    text-align: left;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    padding: 14px 11px;
    border-bottom: 1px solid #EAECF0;
    background: #FCFCFD;
}
.listing-table table tr td i {
    margin-right: 7px;
}
/* .listing-table table tr td a, */
.listing-table table tr td .action,
.search-button {
    right: 0;
    top: -1px;
    border-radius: 60px;
    background: #e89532;
    color: #fff;
    padding: 5px 9px;
    margin: 0;
    border: 0;
    margin-left: 4px;
}

.listing-table-scrl ul li {
    color: #000;
    font-family: Campton-light;
    font-weight: 500;
    position: relative;
    white-space: pre-line;
    display: block;
    font-size: .875em;
    margin-bottom: 0 !important;
    margin-top: 0;
    overflow: auto;
}

.listing-table-scrl ul {
    padding: 0;
    margin: 0;
}

.listing-table {
    padding: 12px 0 5px;
}

.listing-table table {
    border-radius: 10px;
    margin-bottom: 28px;
}

.noresult {
    padding: 15px 0;
    background: #efefef;
    font-size: 13px;
    text-align: center;
}

.listing-table table tr td button {
    color: #ee5d5d;
    font-size: 18px;
    background: transparent;
    border: 0;
    border-radius: 3px;
}
td.activebr span {
    background: #e89532;
    padding: 3px 10px;
    border-radius: 4px;
    color: #fff;
}

td.inactive span {
    background: #ff000061;
    padding: 3px 10px;
    border-radius: 4px;
    color: #000;
}
.listing-table table tr td.action-btns {
    min-height: 71px;
}

.listing-table p {
    font-weight: 600;
}

.listing-table table tr td i {
    color: #e89532;
    font-size: 18px;
    cursor: pointer;
}

.listing-table table tr td img {
    max-width: 57px;
    border-radius: 9px;
    height: 42px;
    width: 100%;
    object-fit: cover;
}

.listing-table table tr td {
    border-bottom: 1px solid #eaecf0;
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    font-size: 14px;
    padding: 12px 11px;
}

.search-c {
    position: absolute;
    left: 10px;
    top: 10px;
}

@media (max-width:1400px) {
    .listing-table table tr th {
        font-size: 13px;
        padding: 14px 9px;
    }

    .listing-table table tr td {
        font-size: 12px;
        padding: 12px 9px;
    }
}

@media (max-width:991px) {
    .listing-table table {
        width: 140%;
    }

    .listing-table-scrl {
        overflow-x: auto;
        width: 100%;
    }

    .listing-table table tr th:first-child {
        width: 62px;
    }
}

@media (max-width:767px) {
    .listing-table table {
        width: 320%;
    }

    .listing-table table tr th:first-child {
        width: 52px;
    }
    .search-c {
        right: 16px;
        top: 13px;
        left: inherit;
    }
    .search-c img {
        max-width: 20px;
    }
}

.action-btns {
    display: flex;
    padding: 17px 14px !important;
}

.action-btns a {
    border-radius: 3px;
    color: #e89532;
    cursor: pointer;
    font-size: 18px;
    margin: 6px 13px;
    text-decoration: none;
}