.about h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
    text-transform: capitalize;
}

.about h2 a {
    background: #e89532;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 6px 20px;
    border-radius: 5px;
}

.about h2 a i {
    margin-right: 5px;
}
