.filter-tab {
    display: flex;
    padding: 0;
    margin: 50px 0 26px;
}

.filter-tab li {
    list-style: none;
    margin-right: 20px;
}

.filter-tab li span.active {
    color: #fff;
    background: #e89532;
    padding: 11px 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e89532;
}

.filter-tab li span.inactive {
    color: #e89532;
    background: #fff;
    padding: 11px 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e89532;
}
