.togglr-btn i {
    margin: 0 10px;
    font-size: 28px;
    color: #e89532;
}
#top-bar-main {
    padding: 10px 25px 10px;
    border-radius: 10px;
    margin-bottom: 40px;
}
.bottoBar
{
    display: none;
}
.logout i {
    color: dimgrey;
    font-size: 22px;
    cursor: pointer;
}
.notfication span img {
    display: none;
}
.logout a img {
    display: none;
}
.user-profile img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    object-fit: cover;
    border-radius: 100px;
    margin-left: 8px;
}
.left-top-bar {
    display: flex;
    align-items: center;
}

.left-top-bar h4 {
    margin-left: 23px;
    font-size: 18px;
    margin-bottom: 0;
    color: #636363;
}
.notfication {
    margin-left: 50px;
    position: relative;
    margin-right: 36px;
}

.notfication i {
    color: dimgrey;
    font-size: 22px;
}
.logout a i {
    margin-right: 5px;
}

.logout a {
    color: #000;
    text-decoration: none;
}
.notfication label {
    position: absolute;
    top: -6px;
    right: -10px;
    background: #e89532;
    border-radius: 100px;
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
}
#top-bar-main .col-md-6:first-child, #top-bar-main .col-md-6:last-child {
    width: 50%;
}
.right-top-bar div h5 {
    font-size: 15px;
    margin: 0;
}
.right-top-bar div p {
    margin: 0;
    padding-right: 25px;
}
.right-top-bar div {
    display: initial;
}
.right-top-bar {
    text-align: right;
}
.notification {
    position: absolute;
    right: -30px;
    transform: translateX(0%);
    min-width: 480px;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddd;
    padding: 0px;
    border-radius: 10px;
    top: 32px;
    z-index: 9;
    max-height: 310px;
    overflow-y: auto;
}
.notification ul li {
    list-style: none;
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 12px 12px;
    border-bottom: 1px solid #ddd;
}
.notfication ul li label {
    right: 13px;
    top: 36px;
    width: 10px;
    height: 10px;
    background: #ff7474;
    border-radius: 100%;
}

.notification ul li img {
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    object-fit: cover;
    margin-right: 10px;
}

.notification a.view_all {
    color: #e89532;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: table;
    font-weight: 500;
    border-top: 1px solid #ddd;
    padding-top: 11px;
}
.notification ul {
    padding: 0;
    margin: 0;
}
@media (max-width:991px)
{
    #top-bar-main {
        padding: 10px 20px;
        margin-bottom: 30px;
    }
    #top-bar-main .col-md-6:first-child {
        width: auto;
    }
    #top-bar-main .col-md-6:last-child {
        width: 50%;
    }
}
@media (max-width:767px)
{
#top-bar-main .col-md-6:last-child {
    width: 50%;
}
#top-bar-main .col-md-6:first-child {
    width: 50%;
}
.bottoBar
{
    display: block;
}
.bottoBar ul li.active span {
    color: #e89532;
}
.bottoBar ul li.active img {
    filter: drop-shadow(0px 100px 0 #ED8536);
    transform: translateY(-100px);
}
.notfication span img {
    display: block;
}
#top-bar-main {
    margin-bottom: 8px;
}
.logout a img {
    display: inline-block;
    position: relative;
    top: -4px;
    opacity: 0.7;
}
.right-sidebar {
    padding-top: 0;
}
.notfication span i {
    display: none;
}
.logout a i {
    display: none;
}
.user-profile img {
    border-radius: 6px;
}
.notification {
    min-width: 320px;
    right: -40px;
}
.togglr-btn {
    display: flex;
    align-items: center;
}
#top-bar-main {
    padding: 10px 13px;
}
.left-top-bar h4 {
    font-size: 13px;
    margin-left: 6px;

}
.logout a {
        font-size: 0;
    }
    .togglr-btn i {
        margin: 0 4px;
        font-size: 19px;
    }
    .user-profile span {
        font-size: 0px;
        width: 0px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .right-top-bar .user-profile {
        order: 3;
    }
    .right-top-bar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .notfication i, .logout i {
        font-size: 18px;
    }

    .user-profile img {
        max-width: 33px;
        max-height: 33px;
        min-width: 33px;
        min-height: 33px; 
        margin-left: 18px;  
    }
    .notfication {
        margin-left: 10px;
        margin-right: 19px;
    }
    .right-top-bar .user-profile {
        display: flex;
        align-items: center;
    }
    .bottoBar {
        position: fixed;
        bottom: 0;
        z-index: 9;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 15px 15px 0 0;
        padding: 16px 0;
        box-shadow: 0 0 10px 1px #dddddd69;
        overflow: hidden;
    }
    .bottoBar ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    .bottoBar ul li a span {
        padding-top: 4px;
        display: table;
        width: 100%;
    }
    .bottoBar ul li {
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 20%;
        padding: 0 5px;
        text-align: center;
    }
    .bottoBar ul li a {
        color: #1c2d3482;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
    }
    .bottoBar ul li a img {
        margin: 0 auto;
        display: table;
    }
    .togglr-btn
    {
        display: none;
    }
}