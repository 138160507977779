.side-bar {
    height: 100vh;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddddddbd;
    overflow-y: auto;
}
.side-bar ul {
    padding: 0;
    margin: 40px 0 0;
}
.side-bar ul li {
    line-height: normal;
    text-align: left;
    padding: 8px 50px 0px 50px;
}

.side-bar ul li.down-arow {
    padding: 24px 20px 15px 65px;
}

.side-bar ul li a {
    color: #141A1E;
    text-decoration: none;
    transition: 0.5s;
    width: 100%;
    display: table;
    padding: 12px 15px;
    border-radius: 6px;
}
.side-bar ul li:hover a {
    color: #e89532;
    font-weight: 600;
    background: #e8953240;
}
.side-bar ul li.down-arow ul li:hover a, .side-bar ul li.down-arow:hover a {
    background: transparent;
    color: #141A1E;
    font-weight: 400;
}

.side-bar ul li:hover a i
{
    color: #e89532;
}
.side-bar ul li i {
    margin-right: 15px;
    font-size: 18px;
    color: #BEC3D6;
}

.side-bar ul li.active a i {
    color: #e89532;
}
.app.active .left-sidebar {
    width: 7%;
    transition: 0.5s;
}
.app.active .left-sidebar ul li.active a i {
    margin: 0;
}

.side-bar ul li.active a {
    color: #e89532;
    font-weight: 600;
    background: #e8953240;
}
.app.active .logo img {
    max-width: 98px;
    transition: 0.5s;
}
.active .left-sidebar a {
    font-size: 0;
    transition: 0.5s;
}
.active .left-sidebar li.down-arow {
    font-size: 0;
}
.side-bar ul li:last-child {
    border-bottom: 0;
}
.side-bar ul.submenu {
    margin: 0;
}

.side-bar ul.submenu li {
    padding: 0 20px 0;
    list-style: none;
}
.side-bar ul.submenu li a {
    padding-left: 16px;
}
.logo img {
    max-width: 150px;
    transition: 0.5s;
}
.logo {
    text-align: center;
    padding-top: 31px;
    padding-bottom: 19px;
}
span.side-br-mobile {
    display: none;
}
.side-bar ul.submenu i {
    display: none;
}

li.down-arow {
    position: relative;
}
li.down-arow:before {
    border: 2px solid #888;
    border-left: 0;
    border-top: 0;
    content: "";
    height: 10px;
    position: absolute;
    right: 23px;
    top: 32px;
    transform: rotate(-45deg);
    width: 10px;
}
.left-sidebar {
    width: 17%;
    height: 100%;
    transition: 0.5s;
}
.right-sidebar {
    width: 83%;
    transition: 0.5s;
    padding-left: 0;
    padding-right: 0px;
    padding-top: 13px;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 20px;
    background-color: #f4f4f487;
    padding: 20px 25px 20px;
}
.app.active .right-sidebar {
    width: 93%;
    transition: 0.5s;
}
.side-bar ul li ul.submenu li a.active {
    color: #e89532;
    font-weight: 600;
    background: #e8953240;
}
.side-bar ul li ul.submenu li a {
    color: #141A1E;
    font-weight: 500;
    background: transparent;
}
@media (max-width:1400px)
{
    .side-bar ul li i {
        margin-right: 8px;
        font-size: 16px;
    }
    .side-bar ul li.down-arow {
        padding: 24px 20px 15px 35px;
    }
    .side-bar ul li {
        padding: 8px 20px 0px 20px;
    }
    .app.active .logo img {
        max-width: 62px;
    }
    .right-sidebar {
        width: 80%;
    }
    .side-bar ul li a {
        font-size: 14px;
    }
    .side-bar ul li {
        font-size: 14px;
    }
    .left-sidebar {
        width: 19%;
    }
    li.down-arow::before {
        width: 8px;
        height: 8px;
        right: 9px;
        top: 34px;
    }
}
@media (max-width:991px)
{
    .left-sidebar {
        width: 39%;
        position: fixed;
        left: 0;
        z-index: 1;
        top: 0;
    }
    .right-sidebar {
        width: 100%;
    }
    span.side-br-mobile {
        display: block;
      }
      .logo {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 21px 0;
    }
    span.side-br-mobile {
        color: #e89532;
        font-size: 22px;
    }
}
@media(max-width:767px)
{
    .left-sidebar {
        width: 100%;
    }
 
    .right-sidebar {
        padding-left: 8px;
        padding-right: 10px;
        height: auto;
        overflow: initial;
    }
    .app.active .right-sidebar {
        width: 100%;
    }
    .side-bar ul li {
        padding: 8px 50px 0px 16px;
    }
    .right-sidebar {
        padding-top: 0;
    }
    .side-bar ul li a {
        padding: 9px 15px;
    }
    .side-bar ul li.down-arow {
        padding: 24px 20px 15px 35px;
    }
    li.down-arow::before {
        right: 19px;
    }   
    .app.active .left-sidebar {
        width: 0;
    }
    .right-sidebar {
        padding-bottom: 80px;
    }
}