.loc-left {
    width: 100%;
    padding: 9px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mobview
{
    display: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}
.newMobPrice {
    display: none;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.refrela {
    padding: 0 10px;
}

.locaton-card p img {
    margin-right: 4px;
    max-width: 21px;
    min-height: auto;
    max-height: inherit;
}

.locaton-card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    width: 100%;
    opacity: 1;
    font-size: 15px;
    color: #fff;
}

.locaton-card {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: #e89532cf;
    padding: 8px 14px;
    border-radius: 3px;
    border: 1px solid #ddd;
    width: 50%;
    float: left;
}

.action-btn button img {
    width: auto;
    height: auto;
    min-height: auto;
    max-height: initial;
    margin: 0;
}

.action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rf-left h6 {
    margin: 0;
    color: #000;
    font-size: 14px;
}

.rf-left span {
    color: #e89532;
    font-weight: 600;
    font-size: 15px;
    padding-left: 5px;
}

.rf-left {
    display: flex;
    align-items: center;
    padding-bottom: 13px;
}

.inner-tab-deisign {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 7px;
    border-radius: 10px;
    margin-bottom: 33px;
    transition: 0.5s;
    position: relative;
    background: #fff;
}

.inner-tab-deisign:hover {
    transition: 0.5s;
    position: relative;
    transform: scale(1.03);
}

.inner-tab-deisign .bed-rooms {
    padding: 0 0 0px;
    border-bottom: 1px solid #ddd9;
    margin-bottom: 12px;
}

.inner-tab-deisign:hover .overlay {
    transform: scale(1);
    transition: 0.5s;
}

.inner-tab-deisign .bed-rooms ul {
    justify-content: flex-start;
    padding-left: 11px;
}

.inner-tab-deisign .bed-rooms ul li {
    display: flex;
    align-items: center;
    margin: 0 7px 0 0;
    font-size: 14px;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    padding: 9px 8px;
}

.inner-tab-deisign .bed-rooms ul li img {
    max-width: 26px;
    opacity: 0.8;
    margin-right: 6px;
    max-height: 20px;
    object-fit: contain;
    border-right: 1px solid #ddd;
    padding-right: 8px;
    min-height: 18px;
}

.inner-tab-deisign .bed-rooms ul li {
    display: flex;
    align-items: center;
    margin: 0 7px 0 0;
    font-size: 14px;
    width: 31.5%;
}

.inner-tab-deisign .bed-rooms ul li:last-child {
    margin: 0;
}

.view-btn {
    background: #ffffff82;
    border: 0;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-right: 13px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
}

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 97%;
    background: #0009;
    left: 0;
    border-radius: 10px;
    display: flex;
    transition: 0.5s;
    transform: scale(0);
    justify-content: center;
}

.new-tab-design {
    padding: 22px 0 0;
}

.top-card-had img {
    max-width: 100%;
    min-height: 230px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 12px;
    max-height: 230px;
    width: 100%;
}

.top-card-had {
    align-items: center;
    padding: 9px 10px 15px;
}


.top-card-had h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 87%;
}

.left-slider {
    width: 100%;
    margin-right: 0px;
    position: relative;
}

.left-slider button.slick-arrow {
    display: none !important;
}

.delet-btn {
    background: #ffffff82;
    border: 0;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-right: 13px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
}


.vendor-listing tr td span i.fas.fa-trash-alt {
    color: #c71a1a;
}

.vendor-listing tr td span i.fas.fa-edit {
    color: #e89532;
}

.vendor-listing tr td span {
    margin-right: 14px;
}

.vendor-listing tr td img {
    width: 45px;
    max-width: 45px !important;
    min-height: 45px;
    min-width: 45px;
    max-height: 50px;
    border-radius: 150px !important;
}

.vendor-listing a.cm-btn:hover,
a.cm-btn:hover {
    color: #fff;
}

.vendor-listing a.cm-btn,
a.cm-btn {
    margin-left: auto;
    float: right;
    background: #e89532;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    padding: 13px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.vendor-listing ul {
    display: flex;
    justify-content: center;
    padding: 0;
}

.vendor-listing ul li {
    margin: 0 22px 0 0;
    list-style: none;
}

.vendor-listing ul li span {
    border: 1px solid #e89532;
    color: #e89532;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 21px;
    border-radius: 5px;
}

.vendor-listing ul li span.active {
    background: #e89532;
    color: #fff !important;
    font-weight: 700;
}

.vendor-listing {
    max-width: 90%;
    margin: 0 auto;
}

.filter-main {
    display: flex;
    justify-content: space-between;
    padding: 0px 0 15px;
    width: 100%;
    align-items: center;
}

.filter-main input {
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 15px;
    min-width: 300px;
}

.filter-main button:hover {
    background: #1b1b1b;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #CCC;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 100px;
}
.slider:before {
    position: absolute;
    content: "";
    border-radius: 100px;
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}
.filter-left {
    display: flex;
}

.filter-left input {
    padding-left: 40px;
    height: 45px;
}

.filer-iic {
    position: absolute;
    left: 10px;
    top: 10px;
}

.filter-left select {
    padding-left: 33px;
}

.filter-left .form-group {
    position: relative;
}
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.add-property-sc {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 40px;
    max-width: 1500px;
    margin: 0 auto;
    border-radius: 11px;
    background-color: white;
}
.add-property-sc input,
.add-property-sc select {
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 5px;
    padding: 0 20px;
}
.add-property-sc .form-group {
    margin-bottom: 15px;
}


.add-property-sc .form-group label {
    margin-bottom: 3px;
}
.form-group.add-image {
    position: relative;
}
.form-group.add-image input {
    border: 0;
    height: 100px;
    width: 100px;
    clear: both;
    display: table;
    background: #fff;
    border: 0;
    font-size: 0;
    padding: 20px;
    position: absolute;
    left: 0;
    top: 42px;
}
.form-group.add-image span {
    border: 1px dashed #ddd;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 28px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    pointer-events: none;
    color: #e89532;
    background: #fff;
}
.selected-images {
    border-radius: 2px;
    border: 1px dashed #e0d6d6;
    padding: 6px 12px;
}
.add-property-sc .form-group.add-image.new-add-gallery span {
    width: 100%;
    border-radius: 4px;
    height: 160px;
    top: -5px;
    position: relative;
}

.add-property-sc .form-group.add-image.new-add-gallery input {
    width: 100%;
    height: 160px;
}
.form-group.add-image .upl-tet {
    width: 100px;
    height: 100px;
    border: 1px dashed #ddd;
    position: relative;
    background: #fff;
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    pointer-events: none;
}
.catTab {
    display: none;
}
@media (max-width:1400px) {
    .filter-main input {
        min-width: 220px;
        font-size: 14px;
    }
}
@media (max-width:767px) {
    .refrela .col-md-6 {
        width: 50%;
    }
    .filter-left {
        position: relative;
        flex-direction: column;
    }
    .filter-main {
        padding: 0px 0 0px;
    }
    .top-card-had {
        padding: 0;
    }
    .inner-tab-deisign {
        padding: 0;
    }
    .top-card-had img {
        min-height: 343px;
        border-radius: 10px 10px 0 0;
        margin-right: 12px;
        max-height: 343px;
        width: 100%;
    }
    .new-tab-design {
        padding: 0px 0 0;
    }
    .locaton-card p {
        font-size: 13px;
        margin-bottom: 17px;
    }

    .rf-left h6 {
        font-size: 12px;
    }
   
    .rf-left span {
        font-size: 12px;
    }

    .vendor-listing {
        max-width: 98%;
    }

    .vendor-listing i {
        font-size: 12px;
        margin: 0 4px 0 0;
    }

    .vendor-listing ul li {
        margin: 0 12px 0 0;
    }

    .vendor-listing .d-flex.justify-content-between.align-items-center {
        flex-wrap: wrap;
    }

    .vendor-listing a.cm-btn,
    a.cm-btn {
        font-size: 14px;
        padding: 11px 16px;
    }

    .filter-main input {
        width: 100%;
        margin: 0 0 10px !important;
        min-width: initial;
    }

    .filter-main {
        flex-wrap: wrap;
    }
    
    .filter-main button {
        width: 100%;
    }
    .filter-left select {
        margin: 0 0 0px !important;
    }

    .filter-left select {
        width: 100%;
        margin: 0 0 0px !important;
    }

    .filter-left .form-group,
    .filter-left {
        width: 100%;
    }
    .filter-main input {
        min-width: 100%;
        font-size: 14px;
        padding-left: 10px;
        padding-right: 33px;
    }
    .bed-rooms, .rf-left {
        display: none;
    }
    .locaton-card {
        position: static;
        width: 100%;
        float: none;
        background: transparent;
        border: 0;
        padding: 0;
    }
    .loc-left{
        flex-direction: column;
        padding: 7px 15px 0;
    }
    .top-card-had h3 {
        margin: 0 0 8px;
        width: 100%;
    }
    .newMobPrice {
        padding: 8px 15px 16px;
    }
    .newMobPrice span {
        color: #ED8536;
        font-size: 18px;
        font-weight: 700;
    }
    .locaton-card p {
        color: #00000075;
    }
    .newMobPrice {
        display: block;
    }
    .mobview
{
    display: inline;
}

.webview
{
    display: none;
}
.inner-tab-deisign {
    margin-bottom: 19px;
}
.catTab {
    display: block;
    padding: 13px 0 5px;
}
.catTab ul {
    display: flex;
    column-gap: 8px;
    padding: 0 3px;
}
.catTab ul li.active span {
    background: #ED8536;
    color: #fff;
}
.catTab ul li a
{
    text-decoration: none;
    color: #000;
}
.catTab ul li {
    list-style: none;
    width: 33.33%;
    text-align: center;
    overflow: hidden;
}
.catTab ul li span {
    background: #fff;
    border: 1px solid #ED8536;
    border-radius: 5px;
    font-size: 15px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-family: Glitten;
    justify-content: center;
    font-weight: 500;
}
.catTab ul li.active img {
    filter: drop-shadow(0px 100px 0 #fff);
    transform: translateY(-100px);
}
}

