.login-page .form-group span {
    position: absolute;
    top: 13px;
    left: 25px;
}

.login-page .form-group label {
    position: absolute;
    top: 13px;
    right: 19px;
    cursor: pointer;
}
.login-page-right {
    padding-left: 70px;
}

.login-page::before {
    position: absolute;
    background: #e8953247;
    left: 0;
    top: 0;
    width: 52.3%;
    content: "";
    height: 100%;
}
.login-page {
    height: 100vh;
    background-image: url(../../../assets/images/loginleft.jpg);
    background-position: top left -32px;
    background-size: 54% 100%;
    background-repeat: no-repeat;
}

.login-page-right {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.login-page-right h5 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 5px;
}
.login-page .form-group img {
    max-width: 20px;
}
.login-page-right p {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
}

.login-page-right form {
    max-width: 395px;
}

.login-page-right form input {
    background: #e8f0fe;
    border-radius: 11.4826px;
    border: 0;
    width: 100%;
    height: 55px;
    padding: 0 58px;
}

.login-page-right form {
    padding-top: 10px;
    width: 100%;
    float: left;
}

.login-page .form-group {
    position: relative;
    margin-bottom: 16px;
}

.login-page .form-group a {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    text-decoration: none;
}

.login-page .form-group button {
    background: #e89532;
    border-radius: 11.4826px;
    border: 0;
    width: 100%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin-top: 28px;
    padding: 14px 0;
}
.copy-right-lo {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
}

.copy-right-lo p {
    font-size: 13px !important;
}

.copy-right-lo ul {
    padding: 0;
    margin-left: auto;
    display: flex;
}
.copy-right-lo ul li {
    margin-left: 10px;
    list-style: none;
}

.copy-right-lo ul li a {
    font-size: 13px;
    color: #231f20;
    opacity: 1;
    text-decoration: none;
}
@media (max-width:1400px)
{
    .login-page-right {
        padding-left: 0px;
    }
    .copy-right-lo p {
        font-size: 11px !important;
    }
    .copy-right-lo ul li a {
        font-size: 11px;
    }
    .login-page-right form {
        max-width: 461px;
    }
}
@media (max-width:991px)
{
    .login-page {
        background: none;
    }
    .login-page::before {
        opacity: 0;
    }
    .login-page-right form {
        margin: 0 auto;
    }
    .login-page-right h5, .login-page-right p {
        text-align: center;
    }
}
@media (max-width:767px)
{
    .copy-right-lo {
        display: none;
    }
}