.paginationContainer {
    padding: 8px 0px;
    border-radius: 7px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin: auto;
}

.pagination-button {
    padding: 7px 15px !important;
    border-radius: 4px !important;
}


.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #e89532;
    border-color: #e89532;
    color: #fff !important;
}

.pagination>li>a {
    border: 1px solid #e89532;
    outline: none;
    cursor: pointer;
    border-radius: 100px;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 0 10px 1px #c6c6c6;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #e89532;
    border-color: #e89532;
    outline: none;
}



.pagination>li>a,
.pagination>li>span {
    color: #e89532 !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 100% !important;
    font-size: 24px;
}

@media (max-width:767px) {
    .pagination>li>a {
        width: 36px;
        height: 36px;
    }

    .pagination>li>a {
        padding: 5px 7px;
    }
    .pagination>li>a {
        width: 32px;
        height: 32px;
    }
}