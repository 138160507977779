.remainingCharacter {
    font-size: 12px;
    color: green;
    margin-bottom: 0px !important;
}

.remainingCharacter span {
    color: black;
    font-weight: 600;
    margin-left: 4px;
}

.closeButton {
    font-size: 12px;
    height: 15px;
    width: 15px;
}

.selectArrow {
    position: absolute;
    right: 15px;
    top: 15px;
}

.descriptionEditor {
    width: 75vw;
    height: 50vh !important;
}

.featuresBody {
    height: 65vh;
    overflow: auto;
    width: 70vw;
}

.amenitiesBody {
    height: 50vh;
    overflow: auto;
}
.amenitiesBody label
{
    width: auto !important;
}
.chk {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.chk:hover input~.checkmark {
    background-color: #ccc;
}

.chk input:checked~.checkmark {
    background-color: #282828;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.chk input:checked~.checkmark:after {
    display: block;
}

.chk .checkmark:after {
    left: 9px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.specialCheckmark {
    background-color: #e89532 !important;
}

.bathrom-options h6 {
    font-size: 20px;
    margin: 6px 0 18px;
}

.bathrom-options {
    background: #f3f3f3;
    padding: 14px;
    border-radius: 12px;
    margin: 9px 0 10px;
}

.add-onother-nbed {
    color: #e89532;
    cursor: pointer;
}

.add-onother-nbed i {
    margin-right: 5px;
}

.gallery-modal {
    width: 70vw;
}

.gallery-modal img {
    width: 100%;
    height: 250px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.selectedGalleryCount {
    position: absolute;
    top: 0;
    right: 19px;
    background: #e89532;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    cursor: pointer;
}

.galleryCount {
    position: absolute;
    top: 0;
    right: 19px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    opacity: 0.5;
    cursor: pointer;
}
input[type="radio"] {
    height: auto;
}
.slide-name h4 {
    color: #4e4439!important;
    font-family: Cambay-Regular,sans-serif!important;
    font-size: 90%;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 5px;
    text-decoration: underline;
}
@media (max-width:767px)
{
    .amenitiesBody {
        height: 68vh;
    }
    .featuresBody {
        height: auto;
        overflow: auto;
        width: 100%;
    }
}