.inner-tabs {
    position: relative;
    background-color: white;
}
.card-shadow {
    padding: 30px;
    border-radius: 15px;
    display: table;
    width: 100%;
    background: #f0f3f7;
}
.table-chart-right.card-shadow {
    height: 100%;
    background: #f0f3f7;
}
.inner-tabs h4 {
    color: #282828;
    font-weight: bold;
}
.inner-tabs h5 {
    color: #2d2d2d;
    padding-right: 30px;
}
.inner-tabs span {
    position: absolute;
    top: 50%;
    right: 21px;
    width: 60px;
    height: 60px;
    background: #e89532;
    color: #fff;
    border-radius: 100px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    display: none;
}   
.card-shadow:hover {
    box-shadow: 0 0 10px 1px #ddd;
    cursor: pointer;
}
 .cenetr-shadow.inner-tabs.card-shadow {
    background: #FFECD5;
}
.proerty-tabs a {
    text-decoration: none;
}
@media (max-width:767px)
{
    .card-shadow {
        margin: 7px 0;
    }
    .proerty-tabs {
        margin: 0 !important;
    }
}